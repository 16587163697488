<template>
  <div class="min-h-screen bg-gray-100 flex items-center justify-center">
    <svg class="animate-spin h-16 w-16 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AutoAccessPage',
  methods: {
    log(message) {
      const timestamp = new Date().toISOString();
      const logMessage = `[${timestamp}] ${message}`;
      console.log(logMessage);
    },
    generateRandomCredentials() {
      const randomString = Math.random().toString(36).substring(2, 15);
      const credentials = {
        email: `user_${randomString}@chatgptten.com`,
        password: `pass_${randomString}`,
      };
      this.log(`生成随机凭证: ${JSON.stringify(credentials)}`);
      return credentials;
    },
    calculateExpirationDate() {
      const now = new Date();
      const expirationDate = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());

      if (expirationDate.getMonth() !== (now.getMonth() + 1) % 12) {
        expirationDate.setDate(0);
      }

      return expirationDate.toISOString();
    },
    async register(credentials) {
      this.log('开始注册流程');
      try {
        this.log(`发送注册请求: ${JSON.stringify(credentials)}`);
        const response = await axios.post('https://chat.chatgptten.com/u/global_users', {
          loginName: credentials.email,
          password: credentials.password,
          invitationCode: "",
          isActive: 1,
          tag: "gpt4,mj,gpt4v",
          expirationTime: this.calculateExpirationDate(),
          code: "",
          email: credentials.email
        });

        this.log(`收到注册响应: ${JSON.stringify(response.data)}`);

        if (response.data.success) {
          this.log('注册成功');
          return true;
        } else {
          this.log(`注册失败: ${response.data.msg}`);
          return false;
        }
      } catch (error) {
        console.error('注册错误:', error);
        this.log(`注册过程中发生错误: ${error.message}`);
        return false;
      }
    },
    async login(credentials) {
      this.log('开始登录流程');
      try {
        const formData = new URLSearchParams();
        formData.append('name', credentials.email);
        formData.append('pwd', credentials.password);

        this.log(`发送登录请求: ${formData.toString()}`);

        const response = await axios.post('https://chat.chatgptten.com/sso/doLogin', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        this.log(`收到登录响应: 状态码 ${response.status}`);

        if (response.status === 200) {
          this.log('登录成功');
          return true;
        } else {
          this.log(`登录失败: 意外的状态码 ${response.status}`);
          return false;
        }
      } catch (error) {
        console.error('登录错误:', error);
        this.log(`登录过程中发生错误: ${error.message}`);
        return false;
      }
    },
    async getUserInfo() {
      this.log('开始获取用户信息');
      try {
        const response = await axios.get('https://chat.chatgptten.com/u/global_users/loginName/session');
        this.log(`收到用户信息响应: ${JSON.stringify(response.data)}`);

        if (response.data.success) {
          this.log('获取用户信息成功');
          return response.data.data;
        } else {
          this.log(`获取用户信息失败: ${response.data.msg}`);
          return null;
        }
      } catch (error) {
        console.error('获取用户信息错误:', error);
        this.log(`获取用户信息过程中发生错误: ${error.message}`);
        return null;
      }
    },
    redirectToChat(userInfo) {
      const { token, id: uid, expirationTime, tag } = userInfo;
      const expiration = new Date(expirationTime).getTime();
      const redirectUrl = `https://chat.chatgptten.com/?token=${token}&uid=${uid}&expiration=${expiration}&tag=${tag}&s=1`;
      this.log(`准备跳转到: ${redirectUrl}`);
      window.location.href = redirectUrl;
    },
    async autoRegisterAndLogin() {
      this.log('开始自动注册和登录流程');
      let userInfo = await this.getUserInfo();

      if (userInfo) {
        this.log('已有用户信息，直接跳转');
        this.redirectToChat(userInfo);
      } else {
        const credentials = this.generateRandomCredentials();
        const registerSuccess = await this.register(credentials);
        if (registerSuccess) {
          const loginSuccess = await this.login(credentials);
          if (loginSuccess) {
            userInfo = await this.getUserInfo();
            if (userInfo) {
              this.redirectToChat(userInfo);
            } else {
              this.log('无法获取用户信息，请稍后重试');
            }
          } else {
            this.log('登录失败，请稍后重试');
          }
        }
      }
      this.log('自动注册和登录流程完成');
    }
  },
  mounted() {
    this.log('组件已挂载，开始自动注册和登录流程');
    this.autoRegisterAndLogin();
  }
};
</script>