<template>
  <div class="bg-black text-white min-h-screen py-8">
    <div class="container mx-auto px-4 max-w-6xl">
      <!-- Netflix 风格的 logo -->
      <div class="mb-8">
        <h1 class="text-4xl font-bold text-red-600">Pixverse 视频生成</h1>
      </div>

      <!-- 标签切换按钮 -->
      <div class="flex space-x-4 mb-8">
        <button
            @click="activeTab = 'text'"
            :class="{ 'bg-red-600': activeTab === 'text', 'bg-gray-800': activeTab !== 'text' }"
            class="px-6 py-2 rounded-full font-semibold transition duration-300"
        >
          文生视频
        </button>
        <button
            @click="activeTab = 'image'"
            :class="{ 'bg-red-600': activeTab === 'image', 'bg-gray-800': activeTab !== 'image' }"
            class="px-6 py-2 rounded-full font-semibold transition duration-300"
        >
          图生视频
        </button>
      </div>

      <!-- 内容区域 -->
      <div class="bg-gray-900 rounded-lg shadow-lg p-6">
        <h2 class="text-2xl font-semibold text-red-600 mb-4">{{ activeTab === 'text' ? '文生' : '图生视频' }}</h2>

        <div class="mb-6">
          <label :for="activeTab === 'text' ? 'textPrompt' : 'imagePrompt'"
                 class="block text-sm font-medium mb-2">视频提示词</label>
          <input
              :id="activeTab === 'text' ? 'textPrompt' : 'imagePrompt'"
              v-model="promptValue"
              type="text"
              :placeholder="activeTab === 'text' ? '描述您想要生成的视频内容' : '描述您想要用作基础的图片'"
              class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500"
              :disabled="isLoading"
          />
        </div>

        <div class="mb-6">
          <label for="aspectRatio" class="block text-sm font-medium mb-2">选择纵横比</label>
          <select
              id="aspectRatio"
              v-model="aspectRatio"
              class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500"
              :disabled="isLoading"
          >
            <option value="16:9">16:9 - 宽屏</option>
            <option value="4:3">4:3 - 标准</option>
            <option value="1:1">1:1 - 正方形</option>
            <option value="9:16">9:16 - 竖屏</option>
          </select>
        </div>

        <div class="mb-6">
          <label for="styleSelect" class="block text-sm font-medium mb-2">选择风格</label>
          <select
            id="styleSelect"
            v-model="selectedStyle"
            class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500 bg-gray-800 text-white"
            :disabled="isLoading"
          >
            <option v-for="style in styles" :key="style.value" :value="style.value">
              {{ style.name }}
            </option>
          </select>
        </div>

        <div v-if="activeTab === 'image'" class="mb-6">
          <label for="imageUpload" class="block text-sm font-medium mb-2">首图</label>
          <input
              id="imageUpload"
              type="file"
              accept="image/*"
              @change="handleImageUpload"
              class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500"
              :disabled="isLoading"
          />
          <p v-if="uploadSuccess" class="mt-2 text-sm text-green-600">图片上传成功!</p>
          <div v-if="uploadedImagePreview" class="mt-2">
            <img :src="uploadedImagePreview" class="w-20 h-20 rounded-md" alt="首图预览">
          </div>
        </div>

        <!-- 在图生视频模式下添加运动控制 -->
        <div v-if="activeTab === 'image'" class="space-y-6">
          <!-- 运动强度滑块 -->
          <div class="mb-6">
            <label class="block text-sm font-medium mb-2">运动强度</label>
            <div class="flex items-center space-x-2">
              <input
                type="range"
                v-model.number="motionStrength"
                min="0"
                max="1"
                step="0.01"
                class="w-full"
                :disabled="isLoading"
              />
              <span class="text-sm">{{ motionStrength }}</span>
            </div>
          </div>

          <!-- 运动比例控制 -->
          <div class="bg-gray-800 p-4 rounded-lg">
            <h3 class="text-lg font-medium mb-4">运动比例控制</h3>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <!-- 水平移动 -->
              <div>
                <label class="block text-sm font-medium mb-2">水平移动</label>
                <div class="flex items-center space-x-2">
                  <input
                    type="range"
                    v-model.number="motionScale.horizontal"
                    min="-5"
                    max="5"
                    step="0.1"
                    class="w-full"
                    :disabled="isLoading"
                  />
                  <span class="text-sm">{{ motionScale.horizontal }}</span>
                </div>
              </div>

              <!-- 垂直移动 -->
              <div>
                <label class="block text-sm font-medium mb-2">垂直移动</label>
                <div class="flex items-center space-x-2">
                  <input
                    type="range"
                    v-model.number="motionScale.vertical"
                    min="-5"
                    max="5"
                    step="0.1"
                    class="w-full"
                    :disabled="isLoading"
                  />
                  <span class="text-sm">{{ motionScale.vertical }}</span>
                </div>
              </div>

              <!-- 旋转 -->
              <div>
                <label class="block text-sm font-medium mb-2">旋转</label>
                <div class="flex items-center space-x-2">
                  <input
                    type="range"
                    v-model.number="motionScale.roll"
                    min="-5"
                    max="5"
                    step="0.1"
                    class="w-full"
                    :disabled="isLoading"
                  />
                  <span class="text-sm">{{ motionScale.roll }}</span>
                </div>
              </div>

              <!-- 缩放 -->
              <div>
                <label class="block text-sm font-medium mb-2">缩放</label>
                <div class="flex items-center space-x-2">
                  <input
                    type="range"
                    v-model.number="motionScale.zoom"
                    min="-5"
                    max="5"
                    step="0.1"
                    class="w-full"
                    :disabled="isLoading"
                  />
                  <span class="text-sm">{{ motionScale.zoom }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 在提示词输入后添加反向提示词输入框 -->
        <div class="mb-6">
          <label for="negativePrompt" class="block text-sm font-medium mb-2">反向提示词</label>
          <input
            id="negativePrompt"
            v-model="negativePrompt"
            type="text"
            placeholder="输入不想要出现的元素（可选）"
            class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500 bg-gray-800 text-white"
            :disabled="isLoading"
          />
        </div>

        <button
            @click="generateVideo(activeTab)"
            class="w-full bg-red-600 text-white px-4 py-3 rounded-md font-semibold hover:bg-red-700 transition duration-300"
            :disabled="isLoading"
        >
          <span v-if="!isLoading">生成视频</span>
          <span v-else class="flex items-center justify-center">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            生成中...
          </span>
        </button>
      </div>

      <!-- 视频结果 -->
      <div v-if="videoUrl && videoDownloadUrl" class="bg-gray-900 rounded-lg shadow-lg p-6 mt-8 relative">
        <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6" role="alert">
          <p class="font-bold text-yellow-700">注意</p>
          <p class="text-yellow-700">此视频链接为临时链接，请尽快下载保存视频，以免链接失效无法访问。</p>
        </div>
        <a target="_blank" :href="videoDownloadUrl" download
           class="inline-block bg-green-500 text-white px-6 py-3 rounded-md font-semibold hover:bg-green-600 transition duration-300 mb-6">
          下载视频
        </a>
        <div class="relative">
          <video-player :options="videoOptions" @error="handleVideoError"
                        class="rounded-lg overflow-hidden" :key="videoUrl">
          </video-player>
        </div>
      </div>

      <!-- 进度条和状态信息 -->
      <div v-if="isLoading"
           class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-8 max-w-md w-full">
          <p class="text-center mb-4 text-gray-800">{{ statusMessage }}</p>
          <div class="w-full bg-gray-200 rounded-full h-2.5 mb-4">
            <div class="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                 :style="{ width: `${progress}%` }"></div>
          </div>
          <div class="flex justify-center">
            <svg class="animate-spin h-8 w-8 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
      </div>

      <!-- 错误信息 -->
      <div v-if="errorMessage" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-8" role="alert">
        <p class="font-bold">错误</p>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, computed, watch} from 'vue'
import axios from 'axios'
import VideoPlayer from './VideoPlayer.vue'

export default {
  components: {VideoPlayer},
  setup() {
    const activeTab = ref('text')
    const textPrompt = ref('')
    const imagePrompt = ref('')
    const aspectRatio = ref('16:9')
    const uploadedImage = ref(null)
    const isLoading = ref(false)
    const taskId = ref('')
    const videoUrl = ref('')
    const videoDownloadUrl = ref('')
    const statusMessage = ref('')
    const progress = ref(0)
    const videoError = ref(null)
    const uploadSuccess = ref(false)
    const errorMessage = ref(null)
    const API_KEY = 'sk-Hc5QgKxL9deBwZfe4a2dE8635c444022B3148507Ad65A602'

    // 使用时间戳防止缓存
    const videoOptions = computed(() => ({
      autoplay: false,
      controls: true,
      sources: videoUrl.value ? [{src: `${videoUrl.value}?t=${Date.now()}`, type: 'video/mp4'}] : []
    }))

    const promptValue = computed({
      get() {
        return activeTab.value === 'text' ? textPrompt.value : imagePrompt.value
      },
      set(value) {
        if (activeTab.value === 'text') {
          textPrompt.value = value
        } else {
          imagePrompt.value = value
        }
      }
    })

    const handleImageUpload = (event) => {
      const file = event.target.files[0]
      if (file) {
        if (file.type.startsWith('image/')) {
          uploadedImage.value = file
          uploadSuccess.value = true
          setTimeout(() => {
            uploadSuccess.value = false
          }, 3000)
        } else {
          alert('请上传有效的图片文件')
          event.target.value = ''
        }
      }
    }

    const uploadImage = async (file) => {
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios.post('https://chat.chatgptten.com/screenshots/img/upload', formData, {
          headers: {'Content-Type': 'multipart/form-data'}
        })
        if (response.data && response.data.success && response.data.data.url) {
          return response.data.data.url;
        } else {
          throw new Error('Invalid response from image upload: ' + response.data.msg);
        }
      } catch (error) {
        console.error('Error uploading image:', error)
        throw error
      }
    }

    const styles = ref([
      { name: '写实风格', value: 'realistic' },
      { name: '动漫风格', value: 'anime' },
      { name: '3D动画', value: 'pixart' },
      { name: 'CG风格', value: 'cg' }
    ])
    const selectedStyle = ref('realistic')

    // 添加运动控制相关的响应式数据
    const motionStrength = ref(0.55) // 默认值
    const motionScale = ref({
      horizontal: -3.3,
      vertical: 2.7,
      roll: 4,
      zoom: -4.4
    })

    // 添加反向提示词
    const negativePrompt = ref('')

    const generateVideo = async (type) => {
      isLoading.value = true
      errorMessage.value = null
      statusMessage.value = '正在初始化视频生成...'
      progress.value = 0
      videoUrl.value = ''
      videoDownloadUrl.value = ''

      try {
        let requestData;
        
        if (type === 'text') {
          requestData = {
            key: API_KEY,
            callback_url: "http://baidu.com",
            prompt: textPrompt.value,
            ratio: aspectRatio.value,
            style: selectedStyle.value,
            seed: Math.floor(Math.random() * 1000000000),
            negative_prompt: negativePrompt.value || 'ugly',
            model: "v3",
            template_id: 303624537709312
          }
        } else {
          requestData = {
            key: API_KEY,
            callback_url: "http://baidu.com",
            prompt: imagePrompt.value,
            image: '',
            model: "v3",
            template_id: 303624537709312,
            motion_strength: motionStrength.value,
            motion_scale: {
              horizontal: motionScale.value.horizontal,
              vertical: motionScale.value.vertical,
              roll: motionScale.value.roll,
              zoom: motionScale.value.zoom
            },
            seed: Math.floor(Math.random() * 1000000000)
          }

          if (uploadedImage.value) {
            const imageUrl = await uploadImage(uploadedImage.value)
            requestData.image = imageUrl
          }
        }

        // 打印生成请求日志
        console.log(`
=== 视频生成请求 ===
时间: ${new Date().toLocaleString()}
类型: ${type}
URL: /api/video/pix/generate
方法: POST
数据: ${JSON.stringify({ ...requestData, key: '***' }, null, 2)}
=========================
        `)

        // 发送生成请求
        const response = await axios.post(`/api/video/pix/generate`, requestData)
        
        // 打印生成响应日志
        console.log(`
=== 视频生成响应 ===
时间: ${new Date().toLocaleString()}
状态码: ${response.status}
数据: ${JSON.stringify(response.data, null, 2)}
=========================
        `)

        // 检查响应状态
        if (response.data.code === 200) {
          taskId.value = response.data.data.task_id
          console.log(`
=== 获取任务ID ===
时间: ${new Date().toLocaleString()}
任务ID: ${taskId.value}
=========================
        `)
          await pollTaskStatus(taskId.value)
        } else {
          throw new Error(response.data.msg || '生成视频请求失败')
        }
      } catch (error) {
        console.log(`
=== 视频生成错误 ===
时间: ${new Date().toLocaleString()}
错误信息: ${error.message}
错误堆栈: ${error.stack}
=========================
        `)
        errorMessage.value = `生成视频时出错: ${error.message}`
        isLoading.value = false
      }
    }

    // 修改轮询函数
    const pollTaskStatus = async (taskId, interval = 5000) => {
      while (isLoading.value) {
        try {
          const pollRequestData = {
            key: API_KEY,
            task_id: taskId
          }

          const response = await axios.post(`/api/video/pix/feed`, pollRequestData)

          if (response.data.code === 200) {
            const data = response.data.data
            const status = data.status
            const state = data.state
            let errorMsg = data.msg || '视频生成失败'

            console.log(`
=== 任务状态更新 ===
时间: ${new Date().toLocaleString()}
任务ID: ${taskId}
状态码: ${status}
执行状态: ${state}
=========================
            `)

            if (status === '3' && state === 'succeeded') {
              videoUrl.value = data.video_url.replace('cdn.duomiapi.com', 'cdn.chatgptten.com')
              videoDownloadUrl.value = videoUrl.value
              isLoading.value = false
              statusMessage.value = '视频生成完成！'
              progress.value = 100
              console.log(`
=== 视频生成完成 ===
时间: ${new Date().toLocaleString()}
任务ID: ${taskId}
视频URL: ${videoUrl.value}
消耗点数: ${data.point}
=========================
              `)
              break
            } else if (status === '2') {
              isLoading.value = false
              errorMessage.value = errorMsg
              console.log(`
=== 视频生成失败 ===
时间: ${new Date().toLocaleString()}
任务ID: ${taskId}
错误信息: ${errorMsg}
=========================
              `)
              break
            } else if (status === '1' || (status === '3' && state !== 'succeeded')) {
              statusMessage.value = '正在处理视频...'
              progress.value = Math.min(progress.value + 10, 90)
              await new Promise(resolve => setTimeout(resolve, interval))
            } else if (status === '0') {
              statusMessage.value = '排队等待中...'
              progress.value = Math.min(progress.value + 5, 30)
              await new Promise(resolve => setTimeout(resolve, interval))
            } else {
              throw new Error('未知的任务状态')
            }
          } else {
            throw new Error(response.data.msg || '获取任务状态失败')
          }
        } catch (error) {
          console.error('轮询错误:', error)
          errorMessage.value = `检查视频状态时出错: ${error.message}`
          await new Promise(resolve => setTimeout(resolve, interval))
        }
      }
    }

    const handleVideoError = (error) => {
      videoError.value = error
      console.error('视频播放器错误:', error)
    }

    const uploadedImagePreview = computed(() => {
      if (uploadedImage.value) {
        return URL.createObjectURL(uploadedImage.value)
      }
      return null
    })

    // 监听 videoUrl 变化，强制更新 videoOptions
    watch(videoUrl, (newVideoUrl) => {
      // 手动更新 videoOptions，触发 video-player 组件更新
      videoOptions.value = {
        autoplay: false,
        controls: true,
        sources: newVideoUrl ? [{ src: `${newVideoUrl}?t=${Date.now()}`, type: 'video/mp4' }] : []
      };
    });

    return {
      activeTab,
      textPrompt,
      imagePrompt,
      promptValue,
      aspectRatio,
      isLoading,
      videoUrl,
      videoDownloadUrl,
      statusMessage,
      progress,
      videoOptions,
      generateVideo,
      handleVideoError,
      handleImageUpload,
      uploadSuccess,
      errorMessage,
      uploadedImagePreview,
      styles,
      selectedStyle,
      motionStrength,
      motionScale,
      negativePrompt,
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

h1, h2, button {
  font-family: 'Bebas Neue', sans-serif;
}

.hover\:scale-105:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

/* 添加滑块样式 */
input[type="range"] {
  @apply appearance-none bg-gray-700 h-2 rounded-lg;
}

input[type="range"]::-webkit-slider-thumb {
  @apply appearance-none w-4 h-4 bg-red-600 rounded-full cursor-pointer;
}

input[type="range"]:disabled {
  @apply opacity-50 cursor-not-allowed;
}
</style>