<template>
  <div class="bg-black text-white min-h-screen py-8">
    <div class="container mx-auto px-4 max-w-6xl">
      <!-- Netflix 风格的 logo -->
      <div class="mb-8">
        <h1 class="text-4xl font-bold text-red-600">Luma 视频生成</h1>
      </div>

      <!-- 标签切换按钮 -->
      <div class="flex space-x-4 mb-8">
        <button
            @click="activeTab = 'text'"
            :class="{ 'bg-red-600': activeTab === 'text', 'bg-gray-800': activeTab !== 'text' }"
            class="px-6 py-2 rounded-full font-semibold transition duration-300"
        >
          文生视频
        </button>
        <button
            @click="activeTab = 'image'"
            :class="{ 'bg-red-600': activeTab === 'image', 'bg-gray-800': activeTab !== 'image' }"
            class="px-6 py-2 rounded-full font-semibold transition duration-300"
        >
          图生视频
        </button>
      </div>

      <!-- 内容区域 -->
      <div class="bg-gray-900 rounded-lg shadow-lg p-6">
        <h2 class="text-2xl font-semibold text-red-600 mb-4">{{ activeTab === 'text' ? '文生视频' : '图生视频' }}</h2>

        <div class="mb-6">
          <label :for="activeTab === 'text' ? 'textPrompt' : 'imagePrompt'"
                 class="block text-sm font-medium mb-2">视频提示词</label>
          <input
              :id="activeTab === 'text' ? 'textPrompt' : 'imagePrompt'"
              v-model="promptValue"
              type="text"
              :placeholder="activeTab === 'text' ? '描述您想要生成的视频内容' : '描述您想要用作基础的图片'"
              class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500"
              :disabled="isLoading"
          />
        </div>

        <div class="mb-6">
          <label for="aspectRatio" class="block text-sm font-medium mb-2">选择纵横比</label>
          <select
              id="aspectRatio"
              v-model="aspectRatio"
              class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500"
              :disabled="isLoading"
          >
            <option value="16:9">16:9 - 宽屏</option>
            <option value="4:3">4:3 - 标准</option>
            <option value="1:1">1:1 - 正方形</option>
            <option value="9:16">9:16 - 竖屏</option>
          </select>
        </div>

        <div v-if="activeTab === 'image'" class="mb-6">
          <label for="imageUpload" class="block text-sm font-medium mb-2">首图</label>
          <input
              id="imageUpload"
              type="file"
              accept="image/*"
              @change="handleImageUpload"
              class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500"
              :disabled="isLoading"
          />
          <p v-if="uploadSuccess" class="mt-2 text-sm text-green-600">图片上传成功!</p>
          <div v-if="uploadedImagePreview" class="mt-2">
            <img :src="uploadedImagePreview" class="w-20 h-20 rounded-md" alt="首图预览">
          </div>
        </div>

        <div v-if="activeTab === 'image'" class="mb-6">
          <label for="imageEndUpload" class="block text-sm font-medium mb-2">尾图</label>
          <input
              id="imageEndUpload"
              type="file"
              accept="image/*"
              @change="handleImageEndUpload"
              class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500"
              :disabled="isLoading"
          />
          <p v-if="endUploadSuccess" class="mt-2 text-sm text-green-600">结束图片上传成功!</p>
          <div v-if="uploadedEndImagePreview" class="mt-2">
            <img :src="uploadedEndImagePreview" class="w-20 h-20 rounded-md" alt="尾图预览">
          </div>
        </div>

        <button
            @click="generateVideo(activeTab)"
            class="w-full bg-red-600 text-white px-4 py-3 rounded-md font-semibold hover:bg-red-700 transition duration-300"
            :disabled="isLoading"
        >
          <span v-if="!isLoading">生成视频</span>
          <span v-else class="flex items-center justify-center">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            生成中...
          </span>
        </button>
      </div>

      <!-- 视频结果 -->
      <div v-if="videoUrl && videoDownloadUrl" class="bg-gray-900 rounded-lg shadow-lg p-6 mt-8 relative">
        <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6" role="alert">
          <p class="font-bold text-yellow-700">注意</p>
          <p class="text-yellow-700">此视频链接为临时链接，请尽快下载保存视频，以免链接失效无法访问。</p>
        </div>
        <a target="_blank" :href="videoDownloadUrl" download
           class="inline-block bg-green-500 text-white px-6 py-3 rounded-md font-semibold hover:bg-green-600 transition duration-300 mb-6">
          下载视频
        </a>
        <div class="relative">
          <video-player :options="videoOptions" @error="handleVideoError"
                        class="rounded-lg overflow-hidden" :key="videoUrl">
          </video-player>
          <!-- 延长视频按钮 -->
          <button
              v-if="!isExtending && videoUrl"
              @click="extendVideo"
              class="absolute top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-md font-semibold hover:bg-blue-600 transition duration-300 z-10"
              :disabled="isLoading"
          >
            <span v-if="!isLoading">延长视频</span>
            <span v-else class="flex items-center justify-center">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              延长中...
            </span>
          </button>
        </div>
      </div>

      <!-- 进度条和状态信息 -->
      <div v-if="isLoading || isExtending"
           class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-8 max-w-md w-full">
          <p class="text-center mb-4 text-gray-800">{{ statusMessage }}</p>
          <div class="w-full bg-gray-200 rounded-full h-2.5 mb-4">
            <div class="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                 :style="{ width: `${progress}%` }"></div>
          </div>
          <div class="flex justify-center">
            <svg class="animate-spin h-8 w-8 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
      </div>

      <!-- 错误信息 -->
      <div v-if="errorMessage" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-8" role="alert">
        <p class="font-bold">错误</p>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, computed, watch} from 'vue'
import axios from 'axios'
import VideoPlayer from './VideoPlayer.vue'

export default {
  components: {VideoPlayer},
  setup() {
    const activeTab = ref('text')
    const textPrompt = ref('')
    const imagePrompt = ref('')
    const aspectRatio = ref('16:9')
    const uploadedImage = ref(null)
    const uploadedEndImage = ref(null)
    const isLoading = ref(false)
    const isExtending = ref(false)
    const taskId = ref('')
    const videoUrl = ref('')
    const videoDownloadUrl = ref('')
    const statusMessage = ref('')
    const progress = ref(0)
    const videoError = ref(null)
    const uploadSuccess = ref(false)
    const endUploadSuccess = ref(false)
    const errorMessage = ref(null)
    const API_URL = 'https://chat.chatgptten.com/luma/internal/v1/api'
    const API_KEY = 'sk-Hc5QgKxL9deBwZfe4a2dE8635c444022B3148507Ad65A602'

    // 使用时间戳防止缓存
    const videoOptions = computed(() => ({
      autoplay: false,
      controls: true,
      sources: videoUrl.value ? [{src: `${videoUrl.value}?t=${Date.now()}`, type: 'video/mp4'}] : []
    }))

    const promptValue = computed({
      get() {
        return activeTab.value === 'text' ? textPrompt.value : imagePrompt.value
      },
      set(value) {
        if (activeTab.value === 'text') {
          textPrompt.value = value
        } else {
          imagePrompt.value = value
        }
      }
    })

    const handleImageUpload = (event) => {
      const file = event.target.files[0]
      if (file) {
        if (file.type.startsWith('image/')) {
          uploadedImage.value = file
          uploadSuccess.value = true
          setTimeout(() => {
            uploadSuccess.value = false
          }, 3000)
        } else {
          alert('请上传有效的图片文件')
          event.target.value = ''
        }
      }
    }

    const handleImageEndUpload = (event) => {
      const file = event.target.files[0]
      if (file) {
        if (file.type.startsWith('image/')) {
          uploadedEndImage.value = file
          endUploadSuccess.value = true
          setTimeout(() => {
            endUploadSuccess.value = false
          }, 3000)
        } else {
          alert('请上传有效的图片文件')
          event.target.value = ''
        }
      }
    }

    const uploadImage = async (file) => {
      const formData = new FormData()
      formData.append('file', file)
      try {
        const response = await axios.post('https://chat.chatgptten.com/screenshots/img/upload', formData, {
          headers: {'Content-Type': 'multipart/form-data'}
        })
        if (response.data && response.data.success && response.data.data.url) {
          return response.data.data.url;
        } else {
          throw new Error('Invalid response from image upload: ' + response.data.msg);
        }
      } catch (error) {
        console.error('Error uploading image:', error)
        throw error
      }
    }

    const generateVideo = async (type) => {
      isLoading.value = true
      errorMessage.value = null
      statusMessage.value = '正在初始化视频生成...'
      progress.value = 0
      videoUrl.value = ''
      videoDownloadUrl.value = ''
      try {
        let requestData = {
          aspect_ratio: aspectRatio.value,
          expand_prompt: true,
          image_url: '',
          image_end_url: ''
        }
        if (type === 'text') {
          requestData.user_prompt = textPrompt.value
        } else {
          requestData.user_prompt = imagePrompt.value
          if (uploadedImage.value) {
            const imageUrl = await uploadImage(uploadedImage.value)
            requestData.image_url = imageUrl
            console.log(`Image uploaded: ${imageUrl}`)
          }
          if (uploadedEndImage.value) {
            const imageEndUrl = await uploadImage(uploadedEndImage.value)
            requestData.image_end_url = imageEndUrl
            console.log(`End Image uploaded: ${imageEndUrl}`)
          }
        }
        const response = await axios.post(`${API_URL}/generations`, requestData, {
          headers: {
            'Authorization': `Bearer ${API_KEY}`,
            'Content-Type': 'application/json'
          }
        })
        if (!response.data.success) {
          throw new Error(response.data.msg || '生成视频请求失败')
        }
        taskId.value = response.data.data.id
        await pollTaskStatus()
      } catch (error) {
        console.error('生成视频时出错:', error)
        errorMessage.value = `生成视频时出错: ${error.message}`
        isLoading.value = false
      }
    }


    const pollTaskStatus = async (maxAttempts = 60, interval = 5000) => {
      let attempts = 0
      while (isLoading.value && attempts < maxAttempts) {
        try {
          const response = await axios.get(`${API_URL}/task`, {
            params: {id: taskId.value},
            headers: {'Authorization': `Bearer ${API_KEY}`}
          })
          if (!response.data.success) {
            throw new Error(response.data.msg || '获取任务状态失败')
          }
          console.log("Server Response:", response.data);
          const {state, video, video_raw} = response.data.data
          if (state === 'completed' && video && video_raw && video_raw.url) {
            videoUrl.value = video.url
            videoDownloadUrl.value = video_raw.url
            isLoading.value = false
            statusMessage.value = '视频生成完成！'
            progress.value = 100
            break
          } else if (state === 'processing') {
            statusMessage.value = '正在处理视频...'
            progress.value = Math.min(progress.value + 10, 90)
          } else if (state === 'pending') {
            statusMessage.value = '排队等待中...'
            progress.value = Math.min(progress.value + 5, 30)
          } else {
            throw new Error('意外的任务状态')
          }
        } catch (error) {
          console.error('轮询任务状态时出错:', error)
          errorMessage.value = `检查视频状态时出错: ${error.message}`
          isLoading.value = false
          break
        }
        attempts++
        await new Promise(resolve => setTimeout(resolve, interval))
      }
      if (attempts >= maxAttempts) {
        errorMessage.value = '视频生成超时，请稍后重试'
        isLoading.value = false
      }
    }

    const handleVideoError = (error) => {
      videoError.value = error
      console.error('视频播放器错误:', error)
    }

    const uploadedImagePreview = computed(() => {
      if (uploadedImage.value) {
        return URL.createObjectURL(uploadedImage.value)
      }
      return null
    })

    const uploadedEndImagePreview = computed(() => {
      if (uploadedEndImage.value) {
        return URL.createObjectURL(uploadedEndImage.value)
      }
      return null
    })

    const extendVideo = async () => {
      isExtending.value = true
      isLoading.value = true
      statusMessage.value = '正在延长视频...'
      try {
        const currentPrompt = activeTab.value === 'text' ? textPrompt.value : imagePrompt.value

        const response = await axios.post(`${API_URL}/generations/${taskId.value}/extend`, {
          aspect_ratio: aspectRatio.value,
          user_prompt: currentPrompt,
        }, {
          headers: {
            'Authorization': `Bearer ${API_KEY}`,
            'Content-Type': 'application/json'
          }
        })

        if (!response.data.success) {
          throw new Error(response.data.msg || '延长视频请求失败')
        }

        taskId.value = response.data.data.id
        await pollTaskStatus()
      } catch (error) {
        console.error('延长视频时出错:', error)
        errorMessage.value = `延长视频时出错: ${error.message}`
      } finally {
        isExtending.value = false
        isLoading.value = false
      }
    }

    // 监听 videoUrl 变化，强制更新 videoOptions
    watch(videoUrl, (newVideoUrl) => {
      // 手动更新 videoOptions，触发 video-player 组件更新
      videoOptions.value = {
        autoplay: false,
        controls: true,
        sources: newVideoUrl ? [{ src: `${newVideoUrl}?t=${Date.now()}`, type: 'video/mp4' }] : []
      };
    });

    return {
      activeTab,
      textPrompt,
      imagePrompt,
      promptValue,
      aspectRatio,
      isLoading,
      isExtending,
      videoUrl,
      videoDownloadUrl,
      statusMessage,
      progress,
      videoOptions,
      generateVideo,
      handleVideoError,
      handleImageUpload,
      uploadSuccess,
      handleImageEndUpload,
      endUploadSuccess,
      errorMessage,
      uploadedImagePreview,
      uploadedEndImagePreview,
      extendVideo
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

h1, h2, button {
  font-family: 'Bebas Neue', sans-serif;
}

.hover\:scale-105:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}
</style>