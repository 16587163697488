<template>
  <div class="h-screen flex flex-col bg-[#121212]">
    <div class="flex-1 flex overflow-hidden">
      <!-- Sidebar -->
      <div class="w-64 bg-black flex-shrink-0">
        <div class="p-6">
          <h1 class="text-2xl font-bold text-white">Musicfy</h1>
        </div>

        <!-- Navigation -->
        <nav class="px-2">
          <a href="#" class="flex items-center px-4 py-2 text-white bg-[#282828] rounded-md">
            <Home class="h-6 w-6 mr-4" />
            Home
          </a>
          <a href="#" class="flex items-center px-4 py-2 text-[#b3b3b3] hover:text-white mt-2">
            <Search class="h-6 w-6 mr-4" />
            Search
          </a>
          <a href="#" class="flex items-center px-4 py-2 text-[#b3b3b3] hover:text-white mt-2">
            <Library class="h-6 w-6 mr-4" />
            Your Library
          </a>
        </nav>

        <!-- Playlists -->
        <div class="mt-8">
          <h2 class="px-6 text-[#b3b3b3] uppercase tracking-wider text-sm font-bold">
            PLAYLISTS
          </h2>
          <div class="mt-4 px-2">
            <div v-for="playlist in playlists" :key="playlist"
                 class="px-4 py-2 text-[#b3b3b3] hover:text-white cursor-pointer">
              {{ playlist }}
            </div>
          </div>
        </div>
      </div>

      <!-- Main Content -->
      <div class="flex-1 overflow-auto">
        <header class="flex items-center justify-between p-4">
          <div class="flex gap-2">
            <button class="p-2 rounded-full bg-black/20 hover:bg-black/40" 
                    @click="handlePrevPage"
                    :disabled="currentPage === 1">
              <ChevronLeft class="h-6 w-6" />
            </button>
            <button class="p-2 rounded-full bg-black/20 hover:bg-black/40"
                    @click="handleNextPage"
                    :disabled="currentPage >= Math.ceil(total / pageSize)">
              <ChevronRight class="h-6 w-6" />
            </button>
          </div>
          <button class="p-1 rounded-full bg-black/20">
            <User class="h-6 w-6" />
          </button>
        </header>

        <main class="px-6 py-4">
          <h2 class="text-2xl font-bold mb-4">Good afternoon</h2>
          <div class="grid grid-cols-5 gap-4 p-4">
            <div v-for="album in albums" :key="album.musicUrl" 
                 @click="playTrack(album)"
                 class="p-4 rounded-lg bg-[#181818] hover:bg-[#282828] transition-colors cursor-pointer">
              <img :src="album.cover" class="w-full aspect-square rounded-md mb-4" />
              <h3 class="text-shades-black font-semibold mb-1">{{ album.name }}</h3>
              <p class="text-[#b3b3b3]">{{ album.artist }}</p>
            </div>
          </div>
          <div v-if="currentTrackLyrics" class="mt-4 p-4 bg-[#181818] rounded-lg text-black">
            <h3 class="text-lg font-bold mb-2">歌词</h3>
            <p>{{ currentTrackLyrics }}</p>
          </div>
        </main>
      </div>
    </div>

    <!-- Player Controls - Fixed at bottom -->
    <div class="h-24 bg-[#181818] border-t border-[#282828] flex items-center px-4">
      <!-- Current Track Info -->
      <div class="w-1/3 flex items-center">
        <img :src="currentTrack.cover" :alt="currentTrack.name" class="h-14 w-14 rounded" />
        <div class="ml-4">
          <div class="text-sm text-white">{{ currentTrack.name }}</div>
          <div class="text-xs text-[#b3b3b3]">{{ currentTrack.artist }}</div>
        </div>
      </div>

      <!-- Playback Controls -->
      <div class="flex-1 flex flex-col items-center">
        <div class="flex items-center gap-4">
          <button class="text-[#b3b3b3] hover:text-white"
                  :class="{ 'text-green-500': isShuffleOn }"
                  @click="handleShuffle">
            <Shuffle class="h-5 w-5" />
          </button>
          <button class="text-[#b3b3b3] hover:text-white"
                  @click="handlePrev">
            <SkipBack class="h-5 w-5" />
          </button>
          <button @click="togglePlay"
                  class="bg-white rounded-full p-2 hover:scale-105 transition-transform">
            <component :is="isPlaying ? Pause : Play" class="h-5 w-5 text-black" />
          </button>
          <button class="text-[#b3b3b3] hover:text-white"
                  @click="handleNext">
            <SkipForward class="h-5 w-5" />
          </button>
          <button class="text-[#b3b3b3] hover:text-white"
                  :class="{ 'text-green-500': isRepeatOn }"
                  @click="handleRepeat">
            <Repeat class="h-5 w-5" />
          </button>
        </div>
        <div class="w-full max-w-md flex items-center gap-2 mt-2">
          <span class="text-xs text-[#b3b3b3]">2:14</span>
          <div class="flex-1 h-1 bg-[#4d4d4d] rounded-full">
            <div class="bg-white h-full rounded-full" :style="{ width: `${progress}%` }" />
          </div>
          <span class="text-xs text-[#b3b3b3]">3:25</span>
        </div>
      </div>

      <!-- Volume Control -->
      <div class="w-1/3 flex justify-end items-center gap-2">
        <Volume2 class="h-5 w-5 text-[#b3b3b3]" />
        <div class="w-24 h-1 bg-[#4d4d4d] rounded-full cursor-pointer"
             @click="handleVolumeChange">
          <div class="bg-white h-full rounded-full" 
               :style="{ width: `${volume}%` }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Home, Search, Library, ChevronLeft, ChevronRight, User, Play, Pause, SkipBack, SkipForward, Repeat, Shuffle, Volume2 } from 'lucide-vue-next'

const playlists = [
  "Recently Added",
  "Recently Played",
  "Top Songs",
  "Top Albums",
  "Top Artists",
  "Logic Discography",
  "Bedtime Beats",
  "Feeling Happy",
  "I miss Y2K Pop",
  "Runtober",
  "Mellow Days",
  "Eminem Essentials"
]

const currentPage = ref(1)
const pageSize = ref(3)
const total = ref(0)
const albums = ref([])

// 获取远程数据
const fetchData = async () => {
  try {
    const response = await fetch(`https://chat.chatgptten.com/api/suno/logs/page?current=${currentPage.value}&size=${pageSize.value}`)
    const data = await response.json()
    
    if (data.success) {
      console.log('Fetched data:', data.data.records)
      albums.value = data.data.records.map(item => ({
        name: item.title,
        artist: new Date(item.submitTime).toLocaleDateString('zh-CN'),
        cover: item.imageUrl || 'https://upload.wikimedia.org/wikipedia/en/9/9f/Midnights_-_Taylor_Swift.png',
        duration: item.duration,
        musicUrl: item.musicUrl,
        description: item.description
      }))
      total.value = data.data.total
    }
  } catch (error) {
    console.error('获取数据失败:', error)
  }
}

// 上一页
const handlePrevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--
    fetchData()
  }
}

// 下一页
const handleNextPage = () => {
  if (currentPage.value < Math.ceil(total.value / pageSize.value)) {
    currentPage.value++
    fetchData()
  }
}

// 组件挂载时获取第一页数据
onMounted(() => {
  fetchData()
  
  // 监听音频进度
  audio.value.addEventListener('timeupdate', () => {
    progress.value = (audio.value.currentTime / audio.value.duration) * 100
  })
  
  // 添加音频结束事件监听
  audio.value.addEventListener('ended', () => {
    if (isRepeatOn.value) {
      // 单曲循环
      audio.value.currentTime = 0
      audio.value.play()
    } else {
      // 播放下一首
      handleNext()
    }
  })
})

// 添加音频对象
const audio = ref(new Audio())

// 修改 currentTrack 的定义
const currentTrack = ref({
  name: "No Track Playing",
  artist: "Unknown Artist",
  cover: "https://gw.chatgptten.com/icons/icon-192x192.png",
  musicUrl: ""
})

// 添加 currentTrackLyrics 状态
const currentTrackLyrics = ref('')

// 修改 playTrack 方法以更新歌词
const playTrack = (track) => {
  currentTrack.value = track
  currentTrackIndex.value = albums.value.findIndex(a => a.musicUrl === track.musicUrl)
  audio.value.src = track.musicUrl
  audio.value.play()
  isPlaying.value = true
  console.log('Playing track:', track)
  currentTrackLyrics.value = track.description || '暂无歌词'
}

// 修改 togglePlay 方法
const togglePlay = () => {
  if (isPlaying.value) {
    audio.value.pause()
  } else {
    audio.value.play()
  }
  isPlaying.value = !isPlaying.value
}

const isPlaying = ref(false)
const progress = ref(33)
const volume = ref(50)

// 添加播放模式相关的状态
const isShuffleOn = ref(false)
const isRepeatOn = ref(false)
const currentTrackIndex = ref(-1)

// 获取当前播放列表中的上一首和下一首
const getNextTrack = () => {
  if (albums.value.length === 0) return null
  
  if (isShuffleOn.value) {
    // 随机播放模式
    const randomIndex = Math.floor(Math.random() * albums.value.length)
    currentTrackIndex.value = randomIndex
    return albums.value[randomIndex]
  } else {
    // 顺序播放模式
    const nextIndex = (currentTrackIndex.value + 1) % albums.value.length
    currentTrackIndex.value = nextIndex
    return albums.value[nextIndex]
  }
}

const getPrevTrack = () => {
  if (albums.value.length === 0) return null
  
  if (isShuffleOn.value) {
    // 随机播放模式
    const randomIndex = Math.floor(Math.random() * albums.value.length)
    currentTrackIndex.value = randomIndex
    return albums.value[randomIndex]
  } else {
    // 顺序播放模式
    const prevIndex = currentTrackIndex.value - 1 < 0 ? albums.value.length - 1 : currentTrackIndex.value - 1
    currentTrackIndex.value = prevIndex
    return albums.value[prevIndex]
  }
}

// 添加控制按钮的处理方法
const handleShuffle = () => {
  isShuffleOn.value = !isShuffleOn.value
}

const handleRepeat = () => {
  isRepeatOn.value = !isRepeatOn.value
}

const handleNext = () => {
  const nextTrack = getNextTrack()
  if (nextTrack) {
    playTrack(nextTrack)
  }
}

const handlePrev = () => {
  const prevTrack = getPrevTrack()
  if (prevTrack) {
    playTrack(prevTrack)
  }
}

// 添加音量控制
const handleVolumeChange = (event) => {
  const rect = event.currentTarget.getBoundingClientRect()
  const x = event.clientX - rect.left
  const percentage = (x / rect.width) * 100
  volume.value = Math.max(0, Math.min(100, percentage))
  audio.value.volume = volume.value / 100
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #666;
  border: 3px solid transparent;
  border-radius: 6px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
  border: 3px solid transparent;
  background-clip: content-box;
}
</style>