<template>
  <div class="min-h-screen bg-gray-100 relative">
    <!-- Main scrollable container -->
    <div class="h-screen overflow-y-auto">
      <!-- Sticky Header -->
      <header class="sticky top-0 bg-white shadow-md p-4 z-10">
        <div class="max-w-7xl mx-auto flex items-center justify-between">
          <div class="flex items-center">
            <img src="https://gw.chatgptten.com/icons/icon-192x192.png?height=40&width=40" alt="ClosedAI Logo"
                 class="w-10 h-10 mr-2"/>
            <h1 class="text-2xl font-bold text-green-600">ClosedAI</h1>
          </div>
          <nav class="flex space-x-4">
            <a href="https://mj.chatgptten.com/pricing" class="text-gray-600 hover:text-green-600">价格</a>
            <a href="https://apifox.chatgptten.com/" class="text-gray-600 hover:text-green-600">文档</a>
            <button @click="goToConsole" class="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600">控制台
            </button>
          </nav>
        </div>
      </header>

      <!-- Main Content -->
      <main class="max-w-7xl mx-auto p-8">
        <section class="mb-16">
          <h2 class="text-3xl font-bold text-center mb-8">计费采用积分消耗制，最低10元起充 无时间限制</h2>
          <p class="text-center text-gray-600 mb-8">
            支持 GPT4.0、dall-e-3、GPT3.5模型等，Claude
            支持 绘画Midjourney Flex Ideogram
            音乐Suno、视频Luma Runway 可用，舞蹈Wiggle
          </p>
          <button @click="goToPurchase"
                  class="bg-green-500 text-white px-6 py-3 rounded-full block mx-auto hover:bg-green-600">
            立即购买GPT4.0
          </button>
        </section>

        <section class="mb-16">
          <h3 class="text-2xl font-bold mb-6">官网GPT公开接口与ClosedAI GPT企业接口优势对比</h3>
          <div class="overflow-x-auto bg-white rounded-lg shadow">
            <table class="w-full border-collapse">
              <thead>
              <tr class="bg-gray-50">
                <th class="border-b border-gray-200 p-3 text-left">功能</th>
                <th class="border-b border-gray-200 p-3 text-left">官网GPT公开接口</th>
                <th class="border-b border-gray-200 p-3 text-left text-purple-600">ClosedAI GPT企业接口</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="border-b border-gray-200 p-3">语言模型</td>
                <td class="border-b border-gray-200 p-3">GPT-3.5/GPT-4o</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">GPT-3.5/GPT-4o</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">调用方式</td>
                <td class="border-b border-gray-200 p-3">直连openAI接口</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">中转openAI接口</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">科学上网</td>
                <td class="border-b border-gray-200 p-3">需要</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">不需要</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">密钥期限</td>
                <td class="border-b border-gray-200 p-3">按月计算</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">无限制</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">GPT3.5资费</td>
                <td class="border-b border-gray-200 p-3">0.002美元/1k tokens</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">0.5元/1百万 tokens</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">GPT4o资费<br>正式7.1</td>
                <td class="border-b border-gray-200 p-3">
                  提问: 0.03美元/1k tokens<br>
                  回答: 0.06美元/1k tokens
                </td>
                <td class="border-b border-gray-200 p-3 text-purple-600">
                  提问: 5元/1百万 tokens<br>
                  回答: 15元/1百万 tokens
                </td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">账号风控</td>
                <td class="border-b border-gray-200 p-3">风险高,风控掉号</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">风险低,长期有效</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">调用限速</td>
                <td class="border-b border-gray-200 p-3">单账号</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">账号池多账号轮询</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">消费门槛</td>
                <td class="border-b border-gray-200 p-3">月120美元</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">低至10元人民币</td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>

        <!-- New Midjourney comparison section -->
        <section class="mb-16">
          <h3 class="text-2xl font-bold mb-6">绘画:Midjourney官网使用与ClosedAI企业接口优势对比</h3>
          <div class="overflow-x-auto bg-white rounded-lg shadow">
            <table class="w-full border-collapse">
              <thead>
              <tr class="bg-gray-50">
                <th class="border-b border-gray-200 p-3 text-left">功能</th>
                <th class="border-b border-gray-200 p-3 text-left">官网使用</th>
                <th class="border-b border-gray-200 p-3 text-left text-purple-600">ClosedAI API企业接口</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="border-b border-gray-200 p-3">使用方式</td>
                <td class="border-b border-gray-200 p-3">登录Discord使用</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">API接口调用</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">科学上网</td>
                <td class="border-b border-gray-200 p-3">需要</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">不需要</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">资费</td>
                <td class="border-b border-gray-200 p-3">0.36人民币/1张<br>10美元/月/200张</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">0.15人民币/1张</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">密钥期限</td>
                <td class="border-b border-gray-200 p-3">按月计算</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">无限制</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">账号风控</td>
                <td class="border-b border-gray-200 p-3">风险高,风控掉号</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">风险低,长期有效</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">调用限速</td>
                <td class="border-b border-gray-200 p-3">单账号</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">账号池多账号轮训</td>
              </tr>
              <tr>
                <td class="border-b border-gray-200 p-3">消费门槛</td>
                <td class="border-b border-gray-200 p-3">大于等于月10美元</td>
                <td class="border-b border-gray-200 p-3 text-purple-600">低至10元人民币</td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>

        <!-- New Features Section -->
        <section class="mb-16">
          <h2 class="text-3xl font-bold text-center mb-8">
            整合全球领先的人工智能技术，提供最新、最高水平的人工智能支持</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <!-- Feature Card: 便利 -->
            <div class="bg-white rounded-lg shadow-md p-6">
              <h3 class="text-xl font-semibold mb-4">便利</h3>
              <p>无需申请 OpenAI API 账号，我们将为您提供一站式解决方案。</p>
            </div>

            <!-- Feature Card: 安全 -->
            <div class="bg-white rounded-lg shadow-md p-6">
              <h3 class="text-xl font-semibold mb-4">安全</h3>
              <p>我们严格遵守 OpenAI API 使用协议，确保用户隐私和数据安全</p>
            </div>

            <!-- Feature Card: 高并发 -->
            <div class="bg-white rounded-lg shadow-md p-6">
              <h3 class="text-xl font-semibold mb-4">高并发</h3>
              <p>拥有自带账号池和高并发处理能力，确保在用户高并发请求时，能够保持高可靠、高效的服务。</p>
            </div>

            <!-- Feature Card: 低延迟 -->
            <div class="bg-white rounded-lg shadow-md p-6">
              <h3 class="text-xl font-semibold mb-4">低延迟</h3>
              <p>部署在全球各地的 API 反代服务器，可为用户选择最优节点，降低调用延迟并提高响应速度。</p>
            </div>

            <!-- Feature Card: 账户精准计费 -->
            <div class="bg-white rounded-lg shadow-md p-6">
              <h3 class="text-xl font-semibold mb-4">账户精准计费</h3>
              <p>和OpenAI完全同步的tokens消耗量，用量计费精确到小数点，每次积分消耗都有明细记录。</p>
            </div>

            <!-- Feature Card: 技术支持 -->
            <div class="bg-white rounded-lg shadow-md p-6">
              <h3 class="text-xl font-semibold mb-4">技术支持</h3>
              <p>我们的专业人员随时待命，为用户提供解答和技术支持，确保用户体验到贴心的服务。</p>
            </div>
          </div>
        </section>

        <!-- Footer -->
        <footer class="bg-gray-800 text-white py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center">
              <div>
                <h4 class="text-lg font-medium mb-4">功能快捷键</h4>
                <ul class="space-y-2">
                  <li><a href="#" class="hover:text-gray-300">GPT快速接入</a></li>
                  <li><a href="#" class="hover:text-gray-300">Midjourney接入</a></li>
                </ul>
              </div>
              <div>
                <h4 class="text-lg font-medium mb-4">相关资源</h4>
                <ul class="space-y-2">
                  <li><a href="#" class="hover:text-gray-300">音乐模型 suno</a></li>
                  <li><a href="#" class="hover:text-gray-300">视频模型 luma</a></li>
                </ul>
              </div>
              <div>
                <h4 class="text-lg font-medium mb-4">联系我们</h4>
                <ul class="space-y-2">
                  <li>QQ: 419644560</li>
                  <li>邮箱: help@chatgptten.com</li>
                </ul>
              </div>
            </div>
            <div class="mt-8 text-center text-sm">
              © 2024 ClosedAI. All rights reserved.
            </div>
          </div>
        </footer>
      </main>
    </div>

    <Teleport to="body">
      <div v-if="showModal" class="modal-outer" @click="closeModal">
        <div class="bg-white rounded-lg shadow-lg p-8 w-full max-w-3xl relative" @click.stop>
          <div class="modal-inner">
            <!-- Modal content goes here -->
            <button @click="closeModal" class="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              <!-- Close button SVG -->
            </button>

            <div class="text-center mb-6">
              <h2 class="text-2xl font-bold text-green-600">最新公告</h2>
            </div>

            <!-- Scrollable content within the modal -->
            <div class="text-sm space-y-4">
              <p class="font-semibold">🎉 Oai/AZ混合线路：</p>
              <p>超高并发，支持识图、函数调用、流式秒回，0.38 元起（1000刀起）</p>
              <p class="font-semibold">🎉 3.5逆向线路：</p>
              <p>高并发，0.1 元起（1000刀起）</p>
              <p>还不会使用的API的小伙伴可以查看→<span class="text-blue-600 cursor-pointer"><a
                  href="https://apifox.chatgptten.com/" class="text-blue-600 hover:underline">基础教程</a></span>，新用户可购买测试卡密 9.9元仅需10刀~，限购一次~再次提醒各位用户注意测试额度的有效期~
              </p>
              <p>GPTs，4all 部分模型限时5折~具体请查看→<span class="text-blue-600 cursor-pointer"><a
                  href="https://news.chatgptten.com/zhi-yan-ai-zhi-chi-de-quan-bu-mo-xing-lie-biao/"
                  class="text-blue-600 hover:underline">模型倍率</a></span></p>
              <p>欢迎使用智言 API，欢迎各大站主对接~联系客服解决您的疑问~会员政策更新请查看最新→</p>
              <p class="font-bold">🎉 智言 VIP客户只需累计消费满 500$，就可以获得8.5折优惠！</p>
              <p>🎉 邀请返利：使用邀请码邀请一名新用户可立刻获得5$，您所邀请的用户每次充值，您都可获得充值的10%<span
                  class="text-blue-600 cursor-pointer"> <a href="https://mj.chatgptten.com/setting?tab=personal"
                                                           class="text-blue-600 hover:underline">查看邀请链接</a></span>
              </p>
              <p>🎯兑换码特惠：→<span class="text-blue-600 cursor-pointer"> <a
                  href="https://chat.chatgptten.com/dist/"
                  class="text-blue-600 hover:underline">购买兑换码</a> </span>
              </p>
              <p>🔘如您有任何想法、问题，或需要购买 AI 大模型，请联系客服 QQ：419644560（务必备注用户名）</p>
              <p>
                🔘出现"quota"字样请检查账户余额是否充足（余额有效期是否过期，若过期，请充值任意数额刷新或联系客服重置），有任何问题请咨询客服解决~</p>
              <p>🔘请各位用户自检接口内容，严禁一切违法违规的请求。在大陆地区运营要遵守《生成式人工智能管理办法》相关规定。</p>
              <p>
                🔘本站用户面向科研，您可以用来获取信息，违规内容会做敏感词过滤处理~请勿上传个人信息，上传个人信息者会做封号处理！</p>
            </div>

            <div class="mt-6 flex justify-center">
              <button @click="closeModal" class="bg-gray-300 hover:bg-gray-400 text-gray-700 font-medium py-2 px-4 rounded-md">
                关闭
              </button>
            </div>
          </div>
        </div>
      </div>
    </Teleport>

  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from 'vue';

const goToConsole = () => {
  window.location.href = 'https://mj.chatgptten.com/setting?tab=personal';
};

const goToPurchase = () => {
  window.location.href = 'https://chatgptten.com/auto-access';
};

const showModal = ref(true);

const closeModal = () => {
  showModal.value = false;
};

// Handle ESC key press
const handleEscKey = (event) => {
  if (event.key === 'Escape' && showModal.value) {
    closeModal();
  }
};

onMounted(() => {
  document.addEventListener('keydown', handleEscKey);
});

onUnmounted(() => {
  document.removeEventListener('keydown', handleEscKey);
});
</script>

<style>
/* Ensure the scrollbar is always visible */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.overflow-y-auto {
  overflow-y: auto; /* Enable vertical scroll if content overflows */
}

.modal-container {
  max-height: 50vh; /* Set the maximum height to 50% of the viewport height */
  overflow-y: auto; /* Add vertical scrollbar if content exceeds the height */
}

.modal-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-inner {
  max-height: 50vh;
  overflow-y: auto;
}


</style>